import React from 'react'
import { Link } from 'react-router-dom'

export default function Intro() {
  return (
    <div className="container-xl py-5" data-aos="fade-up">
        <div className="row justify-content-between align-items-md-end">
            <div className="col-12 col-md-6">
              <p className="h2 mb-0 text-left">Hello, I’m Nelson, <br/>a user interface designer <br/>and front-end developer.</p>
            </div>
            <div className="col-12 col-md-6 text-left text-md-right pt-4 pt-md-0">
              <Link to='/about'>Learn more about me <i className="fa-regular fa-chevron-right"></i></Link>
            </div>
        </div>
    </div>
  )
}
