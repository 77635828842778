import React from 'react'
import { Link } from 'react-router-dom'

export default function ProjectListItem(props) {
  return (
    <div className="project-item overflow-hidden col-12" style={{backgroundColor: props.bgColor}}>
        <div className="row">
            <div className="col-12 col-md-6 px-0">
                <div className="img-container">
                    <img src={props.projectThumbnail} alt="" className="position-absolute w-100 h-100" /></div>
                </div>
            <div className="col-12 col-md-6 p-4 d-flex flex-column justify-content-center">
                <div className='text-left'>
                    <p className="title h3 mb-3">{props.projectName}</p>
                    <p className="description mb-4">{props.projectDescription}</p>
                    <Link to={'/projects/'+props.projectSlug} className="btn btn-black text-white rounded-pill">View project</Link>
                </div>
            </div>
        </div>
    </div>
  )
}
