import React, { useContext } from 'react'
import {Link, useParams} from 'react-router-dom'
import {useState, useEffect} from 'react'
import Paragraph from './Paragraph'
import Iframe from './Iframe'
import ProjectDetailImage from './ProjectDetailImage'
import ProjectDetailVideo from './ProjectDetailVideo'
import ProjectDetailHeader from './ProjectDetailHeader'
import ProjectDetailInfo from './ProjectDetailInfo'
import { ProjectContext } from './ProjectContext'
import CanvasScroll2 from './CanvasScroll2'

export default function ProjectDetail() {

  const {projects} = useContext(ProjectContext)
  let {slug} = useParams() // <= get the params from url
  let [thisProject, setThisProject] = useState(null)

  function updateThisProject() {
    window.scrollTo(0,0)
    setTimeout(()=> {
      let current = projects.find( (project) => {
        return project.slug === slug
      })
      setThisProject(current)
      document.title = current.name+' - Nelson Choi'
    }, 300)
  }

  function getNextProjectId() {
    return (thisProject.id+1 < projects.length ? thisProject.id+1 : 0)
  }

  useEffect( ()=> {
    updateThisProject()
  }, [slug])    

  return (
    <>
    { thisProject && 
      <>
        <ProjectDetailHeader 
          name={thisProject.name} 
          bgColor={thisProject.bgColor} 
          description={thisProject.description} 
          thumbnail={thisProject.thumbnail} 
          altThumbnail={thisProject.altThumbnail}
        />
      
        <ProjectDetailInfo 
          company={thisProject.company}  
          projectType={thisProject.projectType}
          role={thisProject.role}
          link={thisProject.link}
          year={thisProject.year}
          about={thisProject.about}
          goals={thisProject.goals}
          scope={thisProject.scope}
        />
        
        
        { thisProject.sitemapUrl && // print sitemap when it != null
          <div className="container-xl" data-aos="fade-up">
            <Iframe key={thisProject.id} url={thisProject.sitemapUrl} />
          </div>
        }
        

        { thisProject.canvasScroll &&
          <CanvasScroll2
            firstFrame={thisProject.canvasScroll}
            frameCount={thisProject.frameCount}
            frameScrollArea="10000"
          />
        }
        
        
        { thisProject.visualDesign && // print visualDesign when it != null
          <div className="px-2 px-md-2" data-aos="fade-up">
            <div className="container-xl py-5">
              <div className="row justify-content-between align-items-md-start">
                <div className="col-12 col-md-8">
                  <Paragraph title="Visual Design" content={thisProject.visualDesign} />
                </div>
              </div>
            </div>
          </div>
        }
        
        <div>
          {(() => {
            if ( thisProject.visualImageOne && thisProject.visualImageOne.substring(thisProject.visualImageOne.lastIndexOf(".")) === ".mp4" ) {
              return (
                <ProjectDetailVideo videoFrameUrl={thisProject.videoFrame} imageUrl={thisProject.visualImageOne} bgColor={thisProject.bgColor}/>
              )
            } else if ( thisProject.visualImageOne && thisProject.visualImageOne.substring(thisProject.visualImageOne.lastIndexOf(".")) !== ".mp4" ) {
              return (
                <ProjectDetailImage bgColor={thisProject.bgColor} imageUrl={thisProject.visualImageOne} fullViewPortWidth={thisProject.imageFullWidth} />
              )
            } else {
              return (
                <></>
              )
            }
          })()}
          {(() => {
            if ( thisProject.visualImageTwo && thisProject.visualImageTwo.substring(thisProject.visualImageTwo.lastIndexOf(".")) === ".mp4" ) {
              return (
                <ProjectDetailVideo videoFrameUrl={thisProject.videoFrame} imageUrl={thisProject.visualImageTwo} bgColor={thisProject.bgColor}/>
              )
            } else if ( thisProject.visualImageTwo && thisProject.visualImageTwo.substring(thisProject.visualImageTwo.lastIndexOf(".")) !== ".mp4" ) {
              return (
                <ProjectDetailImage bgColor={thisProject.bgColor} imageUrl={thisProject.visualImageTwo} fullViewPortWidth={thisProject.imageFullWidth} />
              )
            } else {
              return (
                <></>
              )
            }
          })()}
          {(() => {
            if ( thisProject.visualImageThree && thisProject.visualImageThree.substring(thisProject.visualImageThree.lastIndexOf(".")) === ".mp4" ) {
              return (
                <ProjectDetailVideo videoFrameUrl={thisProject.videoFrame} imageUrl={thisProject.visualImageThree} bgColor={thisProject.bgColor}/>
              )
            } else if ( thisProject.visualImageThree && thisProject.visualImageThree.substring(thisProject.visualImageThree.lastIndexOf(".")) !== ".mp4" ) {
              return (
                <ProjectDetailImage bgColor={thisProject.bgColor} imageUrl={thisProject.visualImageThree} fullViewPortWidth={thisProject.imageFullWidth} />
              )
            } else {
              return (
                <></>
              )
            }
          })()}
          {(() => {
            if ( thisProject.visualImageFour && thisProject.visualImageFour.substring(thisProject.visualImageFour.lastIndexOf(".")) === ".mp4" ) {
              return (
                <ProjectDetailVideo videoFrameUrl={thisProject.videoFrame} imageUrl={thisProject.visualImageFour} bgColor={thisProject.bgColor}/>
              )
            } else if ( thisProject.visualImageFour && thisProject.visualImageFour.substring(thisProject.visualImageFour.lastIndexOf(".")) !== ".mp4" ) {
              return (
                <ProjectDetailImage bgColor={thisProject.bgColor} imageUrl={thisProject.visualImageFour} fullViewPortWidth={thisProject.imageFullWidth} />
              )
            } else {
              return (
                <></>
              )
            }
          })()}
          {(() => {
            if ( thisProject.visualImageFive && thisProject.visualImageFive.substring(thisProject.visualImageFive.lastIndexOf(".")) === ".mp4" ) {
              return (
                <ProjectDetailVideo videoFrameUrl={thisProject.videoFrame} imageUrl={thisProject.visualImageFive} bgColor={thisProject.bgColor}/>
              )
            } else if ( thisProject.visualImageFive && thisProject.visualImageFive.substring(thisProject.visualImageFive.lastIndexOf(".")) !== ".mp4" ) {
              return (
                <ProjectDetailImage bgColor={thisProject.bgColor} imageUrl={thisProject.visualImageFive} fullViewPortWidth={thisProject.imageFullWidth} />
              )
            } else {
              return (
                <></>
              )
            }
          })()}
        </div>

        <div>
          { thisProject.featureOneTitle && thisProject.featureOneDescription && 
            <div className="px-2 px-md-2" data-aos="fade-up">
              <div className="container-xl py-5">
                <div className="row justify-content-between align-items-md-start">
                  <div className="col-12 col-md-8">
                    <Paragraph title={thisProject.featureOneTitle} content={thisProject.featureOneDescription} />
                  </div>
                </div>
              </div>
            </div>
          }
          {(() => {
            if ( thisProject.featureOneImage && thisProject.featureOneImage.substring(thisProject.featureOneImage.lastIndexOf(".")) === ".mp4" ) {
              return (
                <ProjectDetailVideo videoFrameUrl={thisProject.videoFrame} imageUrl={thisProject.featureOneImage} bgColor={thisProject.bgColor}/>
              )
            } else if ( thisProject.featureOneImage && thisProject.featureOneImage.substring(thisProject.featureOneImage.lastIndexOf(".")) !== ".mp4" ) {
              return (
                <ProjectDetailImage bgColor={thisProject.bgColor} imageUrl={thisProject.featureOneImage} fullViewPortWidth={thisProject.imageFullWidth} />
              )
            } else {
              return (
                <></>
              )
            }
          })()}  
        </div>
        
        <div>
          { thisProject.featureTwoTitle && thisProject.featureTwoDescription && 
            <div className="px-2 px-md-2" data-aos="fade-up">
              <div className="container-xl py-5">
                <div className="row justify-content-between align-items-md-start">
                  <div className="col-12 col-md-8">
                    <Paragraph title={thisProject.featureTwoTitle} content={thisProject.featureTwoDescription} />
                  </div>
                </div>
              </div>
            </div>
          }
          
          {(() => {
            if ( thisProject.featureTwoImage && thisProject.featureTwoImage.substring(thisProject.featureTwoImage.lastIndexOf(".")) === ".mp4" ) {
              return (
                <ProjectDetailVideo videoFrameUrl={thisProject.videoFrame} imageUrl={thisProject.featureTwoImage} bgColor={thisProject.bgColor}/>
              )
            } else if ( thisProject.featureTwoImage && thisProject.featureTwoImage.substring(thisProject.featureTwoImage.lastIndexOf(".")) !== ".mp4" ) {
              return (
                <ProjectDetailImage bgColor={thisProject.bgColor} imageUrl={thisProject.featureTwoImage} fullViewPortWidth={thisProject.imageFullWidth}/>
              )
            } else {
              return (
                <></>
              )
            }
          })()}   
        </div>

        <div className="px-2 px-md-2">
          <div className="container-xl py-5">
              <div className="row justify-content-between align-items-md-start">
                  <div className="col-12 col-md-6">
                      
                  </div>
                  <div className="col-12 col-md-6">
                      <div className="pb-3 text-right">
                      <Link to={"/projects/"+projects[getNextProjectId()].slug} className="h5 font-weight-400 mb-3" >Next project<i className="fa-regular fa-chevron-right"></i></Link>
                      </div>
                  </div>
              </div>
          </div>
        </div>
        
        
      </>
    }
    </>
  )
}
