import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

export default function Disclaimer() {
    
    useEffect( () => {
        document.title = 'Disclaimer – Nelson Choi'
    })
  return (
    <div className="px-2 px-md-2">
        <div className="container-xl py-5 text-left" data-aos="fade-in">
            <p className="h2 mb-4">Disclaimer</p>
            <div className="row justify-content-between align-items-md-start">
                <div className="col-12 col-md-8">
                <p className="mb-4">Unless otherwise specified and/or credited all images, artwork, text and graphics, of this site, are the copyright of Nelson Choi. All rights reserved. All other images are the copyright and/or trademark of the respective owners. Designs, original art and clip art presented are for the use of the authorized client only. All art, images, designs, and material are copyright of Nelson Choi and may not be resold, added to design or art collections of any nature or any other unauthorized form of distribution or reproduction without express written permission by Nelson Choi.</p>
                    <p className="mb-4">All materials featured are offered for individual or commercial information only and as such are offered on a ‘as is’ basis. Any other use of graphic material found on the site including mirroring or copying any part of the site is prohibited without the express written consent of Nelson Choi or authorized representatives.</p>
                    <p className="mb-4">Visitors are permitted, and encouraged to link to this site. You are welcome to link to any page or section of the web site. Any form of reproduction, electronic or otherwise is strictly forbidden without prior written consent. If you have any questions about these terms, or would like information about licensing images and/or materials from Nelson Choi please contact us.</p>
                    <p className="mb-4">The Client unconditionally guarantees that any element of text or graphics given to Nelson Choi for inclusion in designs do not infringe on any copyright or trademarks that have been already established by another company or organization. The Client will hold harmless and protect Nelson Choi from any claim or suit arising from the use of such as furnished by The Client.</p>
                    <p className="mb-4">We make every effort to produce original artwork. If, however, we create artwork for you which accidentally infringes on any existing trademarked artwork, our liability is limited only to a refund of the paid amount. Nelson Choi will do no research checking on the legal availability of the name we are creating designs for. It is the Client’s responsibility to ensure that the name of their product, company or service is not already in use, and it is the Client’s sole responsibility to secure a service mark or trademark to protect the rights to any name or image. We will not be held responsible for any legal action that may result from improper due diligence on the availability of a company name or image.</p>
                    <p className="mb-4">Ownership of the final artwork becomes the full property of the Client, having unlimited, indefinite and unrestricted use of the design upon payment of all fees. Nelson Choi retains the right to display the artwork in their corporate portfolios and marketing materials. All concepts furnished to the client are for client review and comment purposes only. Until all project invoices are paid in full by the Client, Nelson Choi will retain exclusive copyright and ownership of all design and draft materials and any usage will be deemed an infringement of standing copyright protections and pursued appropriately by Nelson Choi. Ownership of final designs are transferred to the client on payment of all fees, however unless otherwise arranged in writing. Nelson Choi retains ownership of all draft materials not used in the final design.</p>
                    <p className="mb-4">Graphics may not be resold, added to design or art collections, as part of a gallery or template collection or redistributed in any manner without express written permission by Nelson Choi.</p>
                </div>
                <div className="col-12 col-md-3 text-left pt-4 pt-md-0">
                    
                </div>
            </div>
        </div>
    </div>
  )
}
