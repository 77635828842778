import React from 'react'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom';

export default function ScrollToTop() {
  let urlPath = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [urlPath]);
}
