import React from 'react'
import {Link} from 'react-router-dom'

export default function Header() {
  return (

      <div className="px-2 px-md-2">
        <div className="container-xl d-flex justify-content-between py-4">
          <Link to='/' className="brand text-decoration-none font-weight-500">Nelson Choi</Link>
          <div className="">
            <Link to='/' className="nav-item text-decoration-none pr-4">Projects</Link>
            <Link to='/about' className="nav-item text-decoration-none">About</Link>
          </div>
        </div>
      </div>
    
  )
}
