import React from 'react';
import ReactDOM from 'react-dom/client';

import $ from 'jquery';
import Popper from 'popper.js';
import "bootstrap/dist/css/bootstrap.min.css"; // Bootstrap CSS
import "bootstrap/dist/js/bootstrap.bundle.min"; // Bootstrap Bundle JS
import AOS from 'aos'; // AOS
import 'aos/dist/aos.css'; // AOS css

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);

// AOS init
AOS.init({
  duration: 800,
  once: true,
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
