import React from 'react'

export default function ProjectDetailVideo(props) {
  return (
    <div className="img-fw-container" style={{backgroundColor:props.bgColor}} data-aos="fade-up">
        <div className="container-xl">
            <div className="position-relative w-100">
                <div className="video-container position-absolute" style={{padding: "9.7% 4.05% 24.22% 4.05%"}}>
                    <video className="w-100" autoPlay playsInline muted loop>
                        <source src={props.imageUrl} type="video/mp4" />
                        Your browser does not support HTML5 video.
                    </video>
                </div>
            </div>
            

            <img src={props.videoFrameUrl} alt="" className="position-relative w-100" style={{pointerEvents: 'none'}} />
        </div>   
    </div>
  )
}
