import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

export default function About() {
    
    useEffect( () => {
        document.title = 'About - Nelson Choi'
    })
  return (
    <div className="px-2 px-md-2">
        <div className="container-xl py-5 text-left" data-aos="fade-in">
            <div className='row d-block d-md-none'>
                <div className='col-10 col-md-2'>
                <img className='w-75 mb-4' src='/images/Profile-Image.jpg' />
                </div>
            </div>
            <p className="h2 mb-4">About Me</p>
            <div className="row justify-content-between align-items-md-start">
                <div className="col-12 col-md-8">
                    <p className="mb-4">I am a user interface designer and front-end developer with a passion for creating impactful products that make a positive difference in people's lives.</p>
                    <p className="mb-4">In 2023, I relocated to Edmonton, Canada, seeking new opportunities to grow and learn in my profession. Prior to my move, I studied and worked in Hong Kong, gaining invaluable experience in the field of user interface design, user experience design and development.</p>
                    <p className="mb-4">Over the years, I have honed my skills in design thinking, user research, and prototyping, among other things. I have had the privilege of working with some amazing clients and projects, and I am always excited to take on new challenges.</p>
                    {/* <p className="mb-4">Design is not just my profession; it's my true passion. I find inspiration in well-designed websites and continually seek opportunities for learning and growth. When encountering websites that fall short, I enjoy envisioning ways to enhance the UI and UX. This curiosity fuels my dedication to design thinking.</p> */}
                    <p className="mb-0">Ultimately, what drives me is the opportunity to create products that can make a positive impact on people's lives. I am committed to using my skills and expertise to create user interfaces that are not only visually stunning but also functional, accessible, and user-friendly.</p>
                </div>
                <div className="col-12 col-md-3 text-left pt-4 pt-md-0">
                    <img className='w-75 mb-4 d-none d-md-block' src='/images/Profile-Image.jpg' />
                    <p><a href="https://drive.google.com/file/d/1vbgxWGtYQfY7ScQo4jGas_x_CujhoBPa/view?usp=sharing" className="mb-3" target="_blank">Resume <i className="fa-regular fa-chevron-right"></i></a></p>
                    <p><a href="mailto:nelsonkhchoi@gmail.com" className="mb-3">Email <i className="fa-regular fa-chevron-right"></i></a></p>
                    <p><a href="https://linkedin.com/in/nelsonchoi98" className="mb-3" target="_blank">LinkedIn <i className="fa-regular fa-chevron-right"></i></a></p>
                </div>
            </div>
        </div>

        <div className="container-xl py-5 text-left" data-aos="fade-in">
            <p className="h2 mb-4">Experience</p>
            <div className="row justify-content-between align-items-md-start">
                <div className="col-12 col-md-8">
                    <p className="mb-4">With a background in graphic design, web design, and front-end development, I have gained extensive experience working in various creative environments. Starting as an intern at a graphic design studio after completing my diploma, I then transitioned to a part-time role as a web designer at a digital agency while pursuing my bachelor's degree.</p>
                    <p className="mb-4">After completing my degree, I joined an IT solution provider as a full-time web designer, where I primarily focused on website design and front-end development. During this time, I gained a deep understanding of web development technologies.</p>
                </div>
                <div className="col-12 col-md-3 text-left pt-4 pt-md-0">
                    <Link to="/" className="mb-3">View previous projects <i className="fa-regular fa-chevron-right"></i></Link>
                </div>
            </div>
        </div>

        <div className="container-xl py-5 text-left" data-aos="fade-in">
            <p className="h2 mb-4">Education</p>
            <div className="row justify-content-between align-items-md-start">
                <div className="col-12 col-md-8">
                    <p className="mb-4">Upon graduating from high school, I pursued my passion for multimedia design by enrolling in The Hong Kong Polytechnic University studying a diploma in multimedia design. After completing this diploma, I went on to earn a bachelor's degree in creative media from City University of Hong Kong. This educational journey provided me with a strong foundation in the principles of design, as well as a deep understanding of multimedia technologies and their applications.</p>
                </div>
                <div className="col-12 col-md-3 text-left pt-4 pt-md-0">
                    
                </div>
            </div>
        </div>
    </div>
  )
}
