import React from 'react'

export default function Paragraph(props) {
  return (

    <div className="paragraph pb-4 text-left">
      <p className="h3 mb-3">{props.title}</p>
      <p className="mb-0">{props.content}</p>
    </div>
    
  )
}
