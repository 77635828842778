import React from 'react'
import {Link} from 'react-router-dom'

export default function Footer() {
  return (
    <div className="px-2 px-md-2">
        <div className="container-xl d-flex flex-column flex-md-row justify-content-between py-5">
            <span className="mb-2 mb-md-0 text-left d-inline-block">© 2023 Nelson Choi | Built with ❤️ using React </span>
            <div className="text-left text-md-right">
              <Link to='/' className="nav-item text-decoration-none pr-4">Projects</Link>
              <Link to='/about' className="nav-item text-decoration-none pr-4">About</Link>
              <Link to='/disclaimer' className="nav-item text-decoration-none pr-4">Disclaimer</Link>
              <a href="https://www.linkedin.com/in/nelsonchoi98/" className="nav-item text-decoration-none" target="_blank"><i className="fa-brands fa-linkedin"></i></a>
            </div>
        </div>
    </div>
  )
}
