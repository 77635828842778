import React from 'react'
import Paragraph from './Paragraph'

export default function ProjectDetailInfo(props) {
  return (
    <div className="px-2 px-md-2 text-left" data-aos="fade-up" data-aos-delay="500">
      <div className="container-xl py-5">
          <div className="row">
            <div className="col-12 col-md-8 text-left pt-md-0">
                <div className="pb-4">
                    <p className="h3 mb-4">{props.about}</p>
                </div>
            </div>
          </div>
          <div className="row py-0 my-0">
            <div className="col-12"><hr /></div>
          </div>
          <div className="row justify-content-between align-items-md-start pt-4">
              <div className="col-12 col-md-8 text-left pt-4 pt-md-0">
                <div className="pb-3">
                    <Paragraph title="Goals" content={props.goals} />
                    <Paragraph title="Scope" content={props.scope} />
                </div>
              </div>
              <div className="col-12 col-md-3 pt-4 pt-md-0">
                {
                  props.company &&
                  <div className="project-info">
                      <span className="title text-muted">Company</span>
                      <p className="description">{props.company}</p>
                  </div>
                }
                {
                  props.projectType &&
                  <div className="project-info">
                      <span className="title text-muted">Project Type</span>
                      <p className="description">{props.projectType}</p>
                  </div>
                }
                {
                  props.role &&
                  <div className="project-info">
                      <span className="title text-muted">My Role</span>
                      <p className="description">{props.role}</p>
                  </div>
                }
                {
                  props.link &&
                  <div className="project-info">
                      <span className="title text-muted">Link</span>
                      <p className="description"><a href={props.link} target="_blank">{props.link}</a></p>
                  </div>
                }
                {
                  props.year &&
                  <div className="project-info">
                      <span className="title text-muted">Year</span>
                      <p className="description">{props.year}</p>
                  </div>
                }
              </div>
          </div>
      </div>
    </div>
  )
}
