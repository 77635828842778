import React from 'react'

export default function ProjectDetailImage(props) {
  return (
    <div className="img-fw-container" style={{backgroundColor: (props.bgColor ? props.bgColor : "")}} data-aos="fade-up">
      <div className={ props.fullViewPortWidth ? "" : "container-xl" }>
        <img src={props.imageUrl} alt="" className="w-100" />
      </div>   
    </div>
  )
}
