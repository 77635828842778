import {useState} from 'react'
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import ProjectList from './components/ProjectList';
import ProjectDetail from './components/ProjectDetail';
import About from './components/About';
import Disclaimer from './components/Disclaimer'
import ScrollToTop from './components/ScrollToTop';
import { ProjectContext } from './components/ProjectContext';

function App() {

  const [projects, setProjects] = useState(() => {
    fetch('/ProjectDetail.json').then( response => response.json() ).then( (data) => setProjects(data))
  })

  return (
    projects &&
    <div className="App">
      <BrowserRouter>
        <Header/>
        <ProjectContext.Provider value={{projects}}>
        <Routes>
          <Route path='/' element={<ProjectList/>}></Route>
          <Route path='/projects' element={<ProjectDetail/>}>
            <Route path=':slug' element={<ProjectDetail/>}></Route>
          </Route>
          <Route path='/about' element={<About/>}></Route>
          <Route path='/disclaimer' element={<Disclaimer/>}></Route>
          <Route path='*' element={<p>404</p>}></Route>
        </Routes>
        </ProjectContext.Provider>
        <Footer/>
        <ScrollToTop />
      </BrowserRouter>
    </div>
  );
}

export default App;
