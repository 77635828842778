import React from 'react'

export default function ProjectDetailHeader(props) {
  return (
    <div className="w-100" style={{backgroundColor:props.bgColor}} data-aos="fade-up" data-aos-delay="300">
            <div className="px-2 px-md-2">
                <div className="container-xl">
                    <div className="project-banner overflow-hidden">
                        <div className="row d-flex flex-row justify-content-between">
                            <div className="col-12 col-md-5 py-4 d-flex flex-column justify-content-center">
                                <div className='text-left'>
                                    <p className="title h1 mb-3">{props.name}</p>
                                    <p className="description mb-0">{props.description}</p>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 px-0">
                                <div className="img-container">
                                    { props.altThumbnail ? <img src={props.altThumbnail} alt="" className="position-absolute w-100 h-100" /> : <img src={props.thumbnail} alt="" className="position-absolute w-100 h-100" /> }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
          </div>
        </div>
  )
}
