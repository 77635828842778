import React from 'react'
import ProjectListItem from './ProjectListItem'
import { useEffect, useContext } from 'react'
import Intro from './Intro'
import { ProjectContext } from './ProjectContext'

export default function ProjectList() {

  const {projects} = useContext(ProjectContext)
  useEffect( ()=> {
    document.title = 'Nelson Choi'
  }, [])
  

  return (
    <div className='px-2 px-md-2'>
      <Intro/>
      <div className="project-listing container-xl py-5 d-flex flex-column" data-aos="fade-up" data-aos-delay="300">
        { projects &&
          projects.map( (project) => {
            return (
            
              <ProjectListItem 
                key={project.id} 
                bgColor={project.bgColor} 
                projectThumbnail={project.thumbnail}
                projectName={project.name}
                projectDescription={project.description}
                projectSlug={project.slug}
              />
              
            )
          } )
        }
      </div>
    </div>
  )
}
