import * as React from "react";
import CanvasScrollClip from "canvas-scroll-clip";

export default function CanvasScroll2({ firstFrame, frameCount, frameScrollArea }) {

  const ref = React.createRef();

  function scaleCanvas() {
    let canvas = document.querySelector("canvas.csc-canvas");
    let s = 0.4; //scale base
    let r = 1.3; // ratio of image (w/h)
    if( window.innerWidth / window.innerHeight < r && canvas != null) {
      
      if( s + window.innerWidth / window.innerHeight > 1 ) {
        canvas.style.scale = s + window.innerWidth / window.innerHeight;
      } else {
        canvas.style.scale = 1;
      }
        
    } else if( canvas != null ) {
      canvas.style.scale = 1;
    }
  }

  React.useEffect(() => {
    new CanvasScrollClip(ref.current, {
      framePath: firstFrame,
      frameCount: frameCount,
      scrollArea: frameScrollArea
    });

    scaleCanvas() // init
    window.addEventListener("resize", () => {
      scaleCanvas();
    });
    

  }, []);


  return (
    <div className="mt-5" ref={ref}></div>
  )
}

